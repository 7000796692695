import { Injectable } from '@angular/core';

import { TagManagerService, TrackingService, WindowRef } from '@frontend/vanilla/core';

import { CommonClientConfig } from '../client-config/client-config.models';

@Injectable({
    providedIn: 'root',
})
export class TagManagerRendererService {
    private isGoogleTagManagerLoaded: boolean = false;

    constructor(
        private tagManagerService: TagManagerService,
        private vanillaTrackingService: TrackingService,
        private commonClientConfig: CommonClientConfig,
        private windowRef: WindowRef,
    ) {}

    renderGTMTag(): void {
        if (this.commonClientConfig && this.commonClientConfig.triggerGoogleTagManagerClientInjection && !this.isGoogleTagManagerLoaded) {
            // Fire App Loaded Event for use as a trigger in GTM - Should be the first event in DataLayer
            this.vanillaTrackingService.triggerEvent('Vanilla_Portal_App_Loaded');

            //Load GTM with a delay
            this.windowRef.nativeWindow.setTimeout(() => {
                this.loadGTM();
            }, this.commonClientConfig.googleTagManagerInjectionDelay);
        }
    }

    private loadGTM(): void {
        // Load GTM Script
        this.tagManagerService.whenReady.subscribe(() => {
            this.tagManagerService.load('GoogleTagManagerRenderer');
            this.isGoogleTagManagerLoaded = true;
        });
    }

    validateEmailFieldByRegex(value: string, regexRange: string[]) {
        if (value && regexRange.length > 0) {
            const regex = new RegExp(regexRange.join('|'), 'g');
            if (value.includes('@') && value.includes('xn--')) {
                const strArray: string[] = value.split('@');
                if (strArray.length > 0) {
                    let emailSecondPart: string = '';
                    strArray.map((currentValue, index) => {
                        currentValue = currentValue.replace(regex, '');
                        if (index > 0) {
                            if (currentValue.includes('.')) {
                                currentValue.split('.').map((splitValue) => {
                                    splitValue = splitValue.includes('--')
                                        ? splitValue.split('--')[1].includes('-')
                                            ? splitValue.split('--')[1].split('-')[0]
                                            : ''
                                        : splitValue;
                                    if (splitValue) emailSecondPart = emailSecondPart ? emailSecondPart + '.' + splitValue : splitValue;
                                    else emailSecondPart = emailSecondPart + '.';
                                });
                                value = value + '@' + emailSecondPart;
                            } else {
                                // As we are getting '--' with unicode (ex: 'pJoseph9669@yopmail.xn--com-yi33ba') after post fixing emoji to email, we are removing the emoji unicode from email string.
                                emailSecondPart = currentValue.includes('--')
                                    ? currentValue.split('--')[1].includes('-')
                                        ? currentValue.split('--')[1].split('-')[0]
                                        : ''
                                    : currentValue;
                                value = value + '@' + emailSecondPart;
                            }
                            emailSecondPart = '';
                        } else {
                            value = currentValue; // emailFirstPart
                        }
                    });
                } else {
                    value = value.replace(regex, '');
                }
            } else {
                value = value.replace(regex, '');
            }
        }
        return value;
    }
}
