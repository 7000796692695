import { APP_BASE_HREF } from '@angular/common';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';

import { provideLoaders } from '@frontend/loaders';
import { AppComponent, bootloader, provideVanillaCore } from '@frontend/vanilla/core';

import { AppModule } from './app.module';
import { environment } from './environments/environment';
import { portalProviders } from './provide';

if (environment.production) {
    enableProdMode();
}

bootloader().then(() => {
    bootstrapApplication(AppComponent, {
        providers: [
            importProvidersFrom(AppModule),
            provideLoaders(),
            provideVanillaCore(),
            provideAnimations(),
            portalProviders(),
            { provide: APP_BASE_HREF, useValue: '/' },
        ],
    })
        .then(() => {})
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
});
