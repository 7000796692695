import { Routes } from '@angular/router';

/*
--> External Redirection routes
--> Used to add routes which do not come under 'mobileportal' parent
    Example: Legacy desktop routes redirections
---> To be imported in app.routes.ts
--> To be exported as a route constant for portal npm package
*/
export const legacyPortalRoutes: Routes = [
    //Redirection to labelhost's cashier
    {
        path: 'mobileportal/cashier',
        redirectTo: '/{culture}/cashier',
        pathMatch: 'full',
    },
    {
        path: 'mobileportal/cashier/:action',
        redirectTo: '/{culture}/cashier/:action',
        pathMatch: 'full',
    },
    // ACCOUNT STATUS REDIRECTS -> remove these once cashier always redirect to mobileportal/accountstatus
    {
        path: 'mobilelogin/kyc',
        redirectTo: 'mobileportal/accountstatus',
    },
    {
        path: 'mobilelogin/kycUk',
        redirectTo: 'mobileportal/accountstatus',
    },
    {
        path: 'mobilelogin/biodata',
        redirectTo: 'mobileportal/accountstatus',
    },
    {
        path: 'mobilelogin/kycgr',
        redirectTo: 'mobileportal/accountstatus',
    },
    {
        path: 'account/upload',
        redirectTo: 'mobileportal/documentupload',
    },
    // DESKTOP TO MOBILE REDIRECTS
    {
        path: 'account/settings',
        redirectTo: 'mobileportal/settings',
    },
    {
        path: 'account/contact',
        redirectTo: 'mobileportal/contact',
    },
    {
        path: 'account/index',
        redirectTo: 'mobileportal/contact',
    },
    {
        path: 'account/depositlimits',
        redirectTo: 'mobileportal/depositlimits',
    },
    {
        path: 'account/depositlimitsv2',
        redirectTo: 'mobileportal/depositlimits',
    },
    {
        path: 'registration',
        redirectTo: 'mobileportal/register',
    },
    {
        path: 'account/bonuses',
        redirectTo: 'mobileportal/mybonuses',
    },
    {
        path: 'account/bonuses/history',
        redirectTo: 'mobileportal/bonushistory',
    },
    {
        path: 'account/recovery',
        redirectTo: 'mobileportal/lostpassword',
    },
    {
        path: 'account/lostpassword',
        redirectTo: 'mobileportal/lostpassword',
    },
    {
        path: 'Account/LostPasswordOverlay',
        redirectTo: 'mobileportal/lostpassword',
    },
    {
        path: 'Account/LostPasswordOverlay/ForgotUserName',
        redirectTo: 'mobileportal/verifyuserdetails',
    },
    {
        path: 'account/recovery/reset',
        redirectTo: 'mobileportal/resetpassword',
    },
    {
        path: 'account/lostpassword/reset',
        redirectTo: 'mobileportal/resetpassword',
    },
    {
        path: 'account/password/change',
        redirectTo: 'mobileportal/changepassword',
    },
    {
        path: 'account/settings/communication',
        redirectTo: 'mobileportal/communication',
    },
    {
        path: 'account/settings/usnjcommunication',
        redirectTo: 'mobileportal/usnjcommunication',
    },
    {
        path: 'account/details',
        redirectTo: 'mobileportal/details',
    },
    {
        path: 'account/money/deposit',
        redirectTo: 'mobileportal/cashier/deposit',
    },
    {
        path: 'account/money/withdraw',
        redirectTo: 'mobileportal/cashier/withdraw',
    },
    {
        path: 'account/money',
        redirectTo: 'mobileportal/transactions',
    },
    {
        path: 'account/money/history',
        redirectTo: 'mobileportal/transactions',
    },
    {
        path: 'account/serviceclosure',
        redirectTo: 'mobileportal/serviceclosure',
    },
    {
        path: 'account/selfexclusion',
        redirectTo: 'mobileportal/selfexclusion',
    },
    {
        path: 'account/promotions',
        redirectTo: 'mobileportal/promotions',
    },
    {
        path: 'account/realitycheckpreferences',
        redirectTo: 'mobileportal/realitycheckpreferences',
    },
    {
        path: 'account/lostpassword',
        redirectTo: 'mobileportal/lostpassword',
    },
    {
        path: 'authentication/login',
        redirectTo: 'mobilelogin/login',
    },
    {
        path: 'authentication/signinform',
        redirectTo: 'mobilelogin/login',
    },
    {
        path: 'account/licenseagreements',
        redirectTo: 'mobileportal/licenseagreements',
    },
    {
        path: 'account/licences',
        redirectTo: 'mobileportal/licences',
    },
    {
        path: 'bfriends-signup',
        redirectTo: 'mobileportal/invitefriendssignup',
    },
    {
        path: 'authentication/termsandconditions/index',
        redirectTo: 'mobileportal/termsandconditions',
    },
    {
        path: 'authentication/screenname/index',
        redirectTo: 'mobileportal/screenname',
    },
    {
        path: 'authentication/status/kyc',
        redirectTo: 'mobileportal/jumiokyc',
    },
    {
        path: 'account/dnicheck',
        redirectTo: 'mobileportal/jumiokyc',
    },
    {
        path: 'authentication/rglimitstest/index',
        redirectTo: 'mobileportal/rgtest',
    },
    {
        path: 'authentication/depositlimitconfirmation/index',
        redirectTo: 'mobileportal/depositlimitincrementconfirmation',
    },
    {
        path: 'authentication/SecureLogin/Recaptcha',
        redirectTo: 'mobileportal/recaptcha',
    },
    {
        path: 'authentication/SecureLogin/securitycode',
        redirectTo: 'mobileportal/twofalogin',
    },
    {
        path: 'authentication/status/redirecttojumio',
        redirectTo: 'mobileportal/jumiokyc',
    },
    {
        path: 'authentication/duplicateemailcheck/index',
        redirectTo: 'mobileportal/duplicateemail',
    },
    {
        path: 'authentication/RgLimitsConfirmationV2/index',
        redirectTo: 'mobileportal/rglimitsconfirmation',
    },
    {
        path: 'account/dnicheck',
        redirectTo: 'mobileportal/jumiokyc',
    },
    {
        path: 'account/settings/communicationv2',
        redirectTo: 'mobileportal/communication',
    },
    {
        path: 'account/status',
        redirectTo: 'mobileportal/accountstatus',
    },
    {
        path: 'mobileportal/recoveryv2/emailorotp',
        redirectTo: 'mobileportal/recovery/emailorotp',
    },
    {
        path: 'mobileportal/recoveryv2/securityquestion',
        redirectTo: 'mobileportal/recovery/securityquestion',
    },
    {
        path: 'mobileportal/recoveryv2/verifyotp',
        redirectTo: 'mobileportal/recovery/verifyotp',
    },
    {
        path: 'mobileportal/recoveryv2/resetpassword',
        redirectTo: 'mobileportal/recovery/resetpassword',
    },
    {
        path: 'mobileportal/recoveryv2/lostpassword',
        redirectTo: 'mobileportal/recovery/lostpassword',
    },
    {
        path: 'mobileportal/recoveryv2/verifyuserdetails',
        redirectTo: 'mobileportal/recovery/verifyuserdetails',
    },
];
